/* AddAddressButton.css */

.add-address-box {
    border: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
  }
  
  .add-address-box.active {
    border: 2px solid #007bff; /* Change border style when active */
    background-color: #f7f7f7; /* Change background color when active */
  }
  
  .add-address-box span {
    color: #333;
  }


  /* AddressHistory.css */
.cancel-symbol {
    position: absolute;
    color: #007bff;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  
  /* Add any additional styles as needed */
  