.label {
    font-size:8px;
    line-height: 1em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff;
    display: block;
    padding: 5px 10px;
    position: absolute;
    background-color: #cc1f1a;
    left: 10px;
    top: 10px;
    z-index: 2;
    border-radius: 3px;
}