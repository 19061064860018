.custom-navbar-bg {
  background-color: "91C8E4";
}


.header-nav .profile {
  min-width: 240px;
  padding-bottom: 0;
  top: 8px !important;
}

.header-nav .profile .dropdown-header h6 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
  color: #444444;
}

.header-nav .profile .dropdown-header span {
  font-size: 14px;
}

.header-nav .profile .dropdown-item {
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
}

.header-nav .nav-profile img {
  max-height: 36px;
}

.custom-card-col {
  margin-bottom: 1rem; /* You can adjust this value as needed */
}


