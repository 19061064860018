* {
  box-sizing: border-box;
}

.ch{
  min-height:100px;
}



.callme_float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 115px;
    right: 45px;
    background-color: #2962ff;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 34px;
    box-shadow: 2px 2px 3px #a8a8a8;
    z-index: 100;
  }
  
  .callme-icon {
    margin-top: 26px;
    
  }
  
  .message_float
{
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 190px;
  right: 45px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 34px;
  box-shadow: 2px 2px 3px #a8a8a8;
  z-index: 100;
}
li {
  text-decoration: none;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
  }

  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
}
path .d {
  width: 0px;
  height: 0px;
}
  

  /* for mobile */
  @media screen and (max-width: 767px) {
    .callme-icon {
      margin-top: 20px;
    }
  
    .callme_float {
      width: 57px;
      height: 59px;
      bottom: 117px;
      right: 46px;
      font-size: 32px;
  
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
   .responsive{
    width:72%;
   }
  }


  @media only screen and  (max-width: 700px) {
    #text-size{
      font-size:30px;
      color: red;
    }
    .car-h{
      height: 300px;
     
    }
    .car-image{
      height: 300px;
    }
    .buttonstyle{
      display: block;
    }
  }

  .buttonstyle{
    display: none;
  }
  @media only screen and  (max-width: 700px) {
    #text-size2{
      font-size:30px;
      color: red;
    }
    .car-h{
      height: 300px;
     
    }
    .car-image{
      height: 300px;
    }
    .buttonstyle{
      display: block;
    }
  }

  @media only screen and  (max-width: 700px) {
    #text-size3{
      font-size:30px;
     
    }
    .car-h{
      height: 300px;
     
    }
    .car-image{
      height: 300px;
    }
    .buttonstyle{
      display: block;
    }


  }
  
  /* @media only screen and  (max-width: 609px) {
    .container{
      background-color: red;

    }
  } */

  @media only screen and  (max-width: 609px) {
    .col-xs-6 button{
      flex-direction: column;
      font-size:10px;
      width: 100px;
      height: 30px;

    }
  }

 